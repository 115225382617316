<template>
  <q-dialog full-height v-model="isShowDialog" persistent>
    <q-card class="dialog-main">
      <q-card-section>
        <div class="text-body1">
          {{ title }}
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="scroll" style="height: 80%">
        <div class="form-input col-6 column">
          <div class="row">
            <span class="title-input-form"
              >Nome da categoria
              <b title="Obrigatório" style="cursor: pointer">*</b>
            </span>
          </div>
          <div class="row">
            <q-input
              class="col-6"
              bg-color="grey-3"
              v-model="newCategoryName"
              color="default-pink"
              dense
              :lazy-rules="true"
              type="text"
              outlined
            />
            <div class="col-5 flex items-start justify-end">
              <DefaultBtn
                :titleButton="!isEditing ? 'adicionar' : 'salvar'"
                @click="
                  (event) => {
                    if (!isEditing) {
                      $emit('addNewCategory', newCategoryName, event);
                    } else {
                      $emit('editCategory', {
                        label: newCategoryName,
                        value: currentIdCategory,
                      });
                      newCategoryName = '';
                      currentIdCategory = null; 
                    }
                  }
                "
              />
            </div>
          </div>
        </div>

        <div class="row">
          <span class="title-input-form">Categoria adicionadas</span>
        </div>
        <div class="form-input col-12 column">
          <Table
            class="full-height"
            :list="listCategory"
            :columns="columnsTable"
          >
            <template v-slot:body="props">
              <div>
                <q-btn
                  dense
                  v-if="canEdit"
                  round
                  color="default-pink"
                  title="Editar"
                  flat
                  @click="
                    (category) => {
                      handleEditCategory(props.props.row);
                    }
                  "
                  icon="o_edit_note"
                />
                <q-btn
                  dense
                  v-if="canRemove"
                  round
                  color="default-pink"
                  title="Remover"
                  flat
                  @click="
                    (category) => {
                      $emit('disableCategory', props.props.row);
                    }
                  "
                  icon="o_delete_outline"
                />
              </div>
            </template>
          </Table>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Fechar"
          color="default-pink"
          @click="
            () => {
              newCategoryName = '';
              currentIdCategory = null;
              isEditing = false;
              $emit('close');
            }
          "
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";

import { ref, watch } from "vue";
import { useQuasar } from "quasar";

export default {
  name: "AddCategotyDialog",
  components: {
    DefaultBtn,
    Table,
  },
  props: {
    title: String,
    listCategory: Array,
    showDialog: Boolean,
    canRemove: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["addNewCategory", "close", "disableCategory", "editCategory"],
  setup(props) {
    const $q = new useQuasar();
    const isShowDialog = ref(props.showDialog);

    let listCategories = ref([]);
    let newCategoryName = ref("");
    let currentIdCategory = ref();
    let isEditing = ref(false);
    let columnsTable = ref([
      {
        name: "label",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.label,
        sortable: true,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);

    function disabled(category) {
      $q.notify({
        message: `Deseja remover o categoria ${category.label}?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: () => {
              _removeCategory(category.label);
            },
          },
        ],
      });
    }

    function _removeCategory(label) {
      listCategories.value = listCategories.value.filter((category) => {
        return category.label != label;
      });
    }

    function handleEditCategory(category) {
      const { label, value } = category;

      newCategoryName.value = label;
      currentIdCategory.value = value;
      isEditing.value = true;
    }

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return {
      listCategories,
      newCategoryName,
      disabled,
      columnsTable,
      handleEditCategory,
      currentIdCategory,
      isEditing,
      isShowDialog,
    };
  },
};
</script>

<style>
.dialog-main {
  width: 50vw;
}
</style>
